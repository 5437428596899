<om-simple-navbar></om-simple-navbar>
<ng-container [ngSwitch]="errorCode">
  <ng-container *ngSwitchCase="RegistrationErrorCodes.PrepaidEnrollmentInvalidCode">
    <om-error-state title="Your membership needs updating">
      <ng-template om-error-state-body-content>
        <div class="mb-4">
          <p>
            Your membership isn't active at the moment. This could be because the payment method failed or the
            membership manager made a change to this account.
          </p>
          <p>The One Medical membership may need to be purchased again on Amazon.</p>
        </div>
        <div class="d-flex justify-content-center">
          <omgui-button
            [variant]="ButtonVariant.primary"
            data-cy="manage-amazon-btn"
            size="fill-parent"
            class="col-md-6 col-sm-8 col m-3"
            [newTab]="true"
            [externalLink]="manageMembershipOnAmazonUrl"
            [trackLink]="
              trackActionSelected({ action: VISIT_AMAZON_CLICKED, destinationLink: manageMembershipOnAmazonUrl })
            "
          >
            Visit Amazon<omgui-external-link-icon></omgui-external-link-icon>
          </omgui-button>
        </div>
      </ng-template>
    </om-error-state>
  </ng-container>

  <ng-container *ngSwitchCase="RegistrationErrorCodes.PrepaidEnrollmentCodeClaimed">
    <om-error-state title="Membership already created">
      <ng-template om-error-state-body-content>
        <p>It looks like this membership has already been set up.</p>
        <p>
          If you purchased multiple memberships and are looking to invite others, log in to the account that manages
          your family's One Medical memberships. You can send invitations from the Membership & Billing page on One
          Medical.
        </p>
        <p
          >Questions? Email our Membership Advisors at
          <a [href]="links.maEmail" class="fw-bolder text-decoration-underline">ma@onemedical.com</a></p
        >
        <div class="d-flex justify-content-center">
          <omgui-button
            [internalLink]="links.login"
            [variant]="ButtonVariant.primary"
            data-cy="existing-membership-error-login"
            size="fill-parent"
            class="col-md-6 col-sm-8 col m-3"
            [trackLink]="trackActionSelected({ action: LOG_IN_WITH_EXISTING_ACCOUNT_SUBMITTED })"
          >
            Log In
          </omgui-button>
        </div>
      </ng-template>
    </om-error-state>
  </ng-container>

  <ng-container *ngSwitchCase="RegistrationErrorCodes.LoginWithAmazonEnrollmentDefault">
    <om-error-state title="Something went wrong">
      <ng-template om-error-state-body-content>
        <p>At this time, you can't log in with Amazon.</p>
        <p>To create your One Medical account, please sign up manually.</p>
        <div class="d-flex justify-content-center">
          <omgui-button
            [variant]="ButtonVariant.primary"
            data-cy="lwa-default-error-button"
            size="fill-parent"
            class="col-md-6 col-sm-8 col m-3"
            [newTab]="false"
            [internalLink]="links.registrationFamily"
            [queryParams]="{ claim_code: claimCode, source: AUTH0_LOGIN_PAGE }"
            [trackLink]="trackActionSelected({ action: SIGN_UP_MANUALLY_SUBMITTED })"
          >
            Sign Up
          </omgui-button>
        </div>
      </ng-template>
    </om-error-state>
  </ng-container>

  <ng-container *ngSwitchCase="RegistrationErrorCodes.DuplicateExternalId">
    <ng-container *ngTemplateOutlet="couldNotLogInWithAmazon"></ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="RegistrationErrorCodes.LoggingInWithAmazonWithoutLinkedPatientAccount">
    <ng-container *ngTemplateOutlet="couldNotLogInWithAmazon"></ng-container>
  </ng-container>

  <ng-container *ngSwitchDefault>
    <om-error-state title="Something went wrong">
      <ng-template om-error-state-body-content>
        <p>A glitch on our end is causing a problem. Wait a minute and try again.</p>
        <p
          >If the issue persists, contact us at
          <a [href]="links.techsupportEmail" class="fw-bolder text-decoration-underline"
            >techsupport@onemedical.com</a
          ></p
        >
      </ng-template>
    </om-error-state>
  </ng-container>
</ng-container>

<ng-template #couldNotLogInWithAmazon>
  <om-error-state title="How to get set up with your Amazon account">
    <ng-template om-error-state-body-content>
      <p class="mb-1"
        >If you're an existing One Medical member, log in with your email and password. You can switch your login method
        to Amazon on the Login &amp; Security page.</p
      >
      <div class="d-flex justify-content-center mt-3">
        <omgui-button
          [variant]="ButtonVariant.primary"
          data-cy="could-not-log-in-with-amazon-primary-button"
          size="fill-parent"
          class="col"
          [newTab]="false"
          [externalLink]="securityDeepLinkUrl"
        >
          Log In
        </omgui-button>
      </div>
      <hr class="divider" />
      <p class="mb-1 text-center"> Not a member yet?</p>
      <div class="d-flex justify-content-center my-3">
        <omgui-button
          [variant]="ButtonVariant.secondary"
          data-cy="could-not-log-in-with-amazon-secondary-button"
          size="fill-parent"
          class="col"
          [newTab]="false"
          [internalLink]="links.registrationSignup"
        >
          Sign Up
        </omgui-button>
      </div>
    </ng-template>
  </om-error-state>
</ng-template>
